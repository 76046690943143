import './App.css';
import React from 'react';
import logo from './Vet_Directory_Dark.png';
import SearchForm, { RegionOption } from './components/SearchForm';
import VetInfoDialog from './components/VetInfoDialog';
import TosDialog from './components/TosDialog';
import { DataGrid, GridActionsCellItem, GridColDef, GridColumnHeaderParams, GridColumns, GridRowModel, GridRowsProp } from '@mui/x-data-grid';
import { randomId } from '@mui/x-data-grid-generator';
import { Tooltip, Box, Container, Typography, Grid, useMediaQuery, useTheme, Link, Divider } from '@mui/material';
import ReadMoreIcon from '@mui/icons-material/ReadMore';

function App() {

    const initialRows: GridRowsProp = [
        { id: randomId(), given_Name: '', surname: '', email: '', phone: '', registrationNumber: '', specialties: '', region_State: '', rights: { value1: false, value2: false, value3: false, value4: false, value5: false, value6: false, value7: false}, },
    ];

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));

    const [results, setResults] = React.useState(initialRows);

    const handleSearch = (params: [{
        givenName: string;
        surname: string;
        email: string;
        phone: string;
        registrationNumber: string;
        specialities: string[];
        region_State: RegionOption;
    }]) => {

        const rows = params.map(result => ({
            id: randomId(), ...result
        }));
            //console.log(rows);
        setResults(rows);

    };

    const [open, setOpen] = React.useState(false);
    const [tosOpen, setTosOpen] = React.useState(false);
    const [vetId, setVetId] = React.useState('1234567');

    const handleOpen = React.useCallback(
    (id: string) => () => {
        setVetId(id);
        setOpen(true);
    },
    [],
    );

    const handleTosOpen = () => {
        //console.log("TOS Open");
        setTosOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setTosOpen(false);
    };

    const CheckboxHeaderRenderer = (props: { keys: string[] }) => {
        return (
            <div className="checkbox-header">
                {props.keys.map((key) => (
                    <div key={key}>
                        {key}
                    </div>
                ))}
            </div>
        );
    };

    const columns: GridColumns = [
        { field: 'region_State', headerName: 'Region', flex: 1 },
        { field: 'registrationNumber', headerName: 'Registration Number', flex: 1 },
        { field: 'givenName', headerName: 'Given Name', flex: 1.75 },
        { field: 'surname', headerName: 'Surname', flex: 1.75 },
        { field: 'registrationType', headerName: 'Registration Type', flex: 1.5 },
        //{
        //    field: 'rights',
        //    headerName: 'Prescribe/Dispense Rights',
        //    sortable: false,
        //    flex: 5,
             
        //    renderCell: (params) => (
        //        <>
        //            {Object.keys(params.row.rights).map((key) => (
        //                <input
        //                    type="checkbox"
        //                    key={key}
        //                    checked={params.row.rights[key]}
        //                    readOnly
        //                />
        //            ))}
        //        </>
        //    ),

        //    renderHeader: (params) => (
        //        <CheckboxHeaderRenderer keys={['Can Dispense S4', 'Can Dispense S4D', 'Can Dispense S8', 'Can Prescribe S4', 'Can Prescribe S4D', 'Can Prescribe S8',  'Can Supervise']} />
        //    ),
        //},

        //    { field: 'specialities', headerName: 'Specialities', flex: 3.3 },
     ];

    return (
        <div className="App">
            <Container maxWidth="xl">
                <header className="App-header">
                    {/*<Box sx={{ pr: isSmallScreen ? 'calc(10vw - 32px)' : '32px', pt: isSmallScreen ? 2 : 0 }}>*/}
                    {/*    <Typography variant="h4">Vet Directory </Typography>*/}
                    {/*</Box>*/}
                    <Box
                        component="img"
                        sx={{
                            height: isSmallScreen ? '6vh' : isMediumScreen ? '7.5vh' : '9vh',
                            position: 'absolute',
                        //    right: isSmallScreen ? 12 : 24,
                        }}
                        alt="VetRx Logo"
                        src={logo}
                    />
                </header>
                <Typography variant="h5" sx={{ p: 2 }}>Find a vet...</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12}>{/* sm={6} md={4} lg={3}>*/}
                        <SearchForm onSearch={handleSearch} />
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{ height: '400px', width: '100%' }}>
                            <DataGrid rows={results} columns={columns}  />
                        </Box>
                    </Grid>
                </Grid>
            </Container>
            <VetInfoDialog open={open} onClose={handleClose} vetId={vetId} />
            <TosDialog open={tosOpen} onClose={handleClose} />
            <Grid container>
                <Grid item xs={4}/>
                <Grid item xs={2}>
                    <Link variant="body2" href="https://vetrx.com.au/" underline="none">Powered by VetRx</Link>
                </Grid>
                <Grid item xs={2}>
                    <Link component="button" variant="body2" onClick={() => handleTosOpen()} underline="none" gutterBottom>Terms of Service</Link>
                </Grid>
                <Grid item xs={4}/>
            </Grid>

        </div>
    );
}

export default App;
