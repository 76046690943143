import React, { useEffect, useState } from 'react';

import axios from 'axios';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	Typography,
	List,
	ListItem,
	ListItemText,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Divider,
    ListItemIcon,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import { red, green } from '@mui/material/colors';

interface Vet {
	$id: string;
	canDispenseS4: boolean;
	canDispenseS4D: boolean;
	canDispenseS8: boolean;
	canPrescribeS4: boolean;
	canPrescribeS4D: boolean;
	canPrescribeS8: boolean;
	canSupervise: boolean;
	givenName: string;
	//salutation: string | null;
	//post_Nominals: string | null;
	//created_At: string | null;
	//updated_At: string | null;
	region_State: string;
	registrationNumber: string;
	registrationType: string;
	specialities: {
		$id: string;
		$values: any[]; // Replace with the proper structure when available
	};
	surname: string;
	vetId: string;
}

interface VetInfoDialogProps {
	open: boolean;
	onClose: () => void;
	vetId: string;

}

const VetInfoDialog: React.FC<VetInfoDialogProps> = ({ open, onClose, vetId }) => {
	const [vet, setVet] = useState<Vet | null>(null);

	useEffect(() => {
		if (open) {
			const fetchVetData = async () => {
				try {
					//const response = await axios.get(`http://localhost/FindVetDetailed?${vetId}`, {
					const response = await axios.get(`https://api.vetdirectory.info/FindVet?${vetId}`, {
						params: {
							vetId: vetId,
						},
					});
					//console.log("Response ");
					//console.log(response.data[0]);
					setVet(response.data[0]);
				} catch (error) {
					console.error('Error fetching vet data:', error);
				}
			};

			fetchVetData();
		}
	}, [open, vetId]);

	if (!vet) {
		return null;
	}

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
			<DialogTitle>
				<Typography variant='h4'> {vet.givenName} {vet.surname} <Typography variant='caption'></Typography></Typography>
			</DialogTitle>
			<DialogContent dividers>
				{/*<Typography variant='body2'>Email: {vet.email}</Typography>*/}
				{/*<Typography variant='body2'>Phone: {vet.phone}</Typography>*/}
				{/*<Typography variant='body2'>VetRx ID: {vet.vetRx_Id}</Typography>*/}
								{/*({vet.post_Nominals})|{vet.salutation} Created Date: ${new Date(vet.created_At!).toLocaleDateString()}} */}

                <>
                    <Typography variant="h5">Registration</Typography>
                    <List>
                        <ListItem key={vet.$id}>
                            <ListItemText
								primary={`State: ${vet.region_State} - Registration Number: ${vet.registrationNumber}`}
								secondary={`Status: ${vet.registrationType}`}
                            />
                        </ListItem>
                    </List>
                </>
				{/*}*/}

				{/*{vet.vet_Qualifications.$values.length > 0 &&*/}
				{/*	<>*/}
				{/*		<Typography variant="h5">Qualifications</Typography>*/}
				{/*		<List>*/}
				{/*			{vet.vet_Qualifications.$values.map((qualification) => (*/}
				{/*				<ListItem key={qualification.$id}>*/}
				{/*					<ListItemText*/}
				{/*						primary={qualification.qualification.title}*/}
				{/*						secondary={`${qualification.qualification.post_Nominal} | ${qualification.qualification.description}`}*/}
				{/*					/>*/}
				{/*				</ListItem>*/}
				{/*			))}*/}
				{/*		</List>*/}
				{/*	</>*/}
				{/*}*/}
				{vet.specialities.$values.length > 0 &&
					<>
						<Typography variant="h5">Specialities</Typography>
						<List>
							{vet.specialities.$values.map((speciality) => (
								<ListItem key={speciality.$id}>
									<ListItemText
										primary={speciality.title}
										secondary={`Code: ${speciality.speciality_Code}`}
									/>
								</ListItem>
							))}
						</List>
					</>
				}
				<>
					<Typography variant="h5">Prescribing and Dispensing Rights</Typography>
					<List>
						<ListItem key="1">
							<ListItemIcon>
								{(vet.canPrescribeS4 && vet.registrationType !== "removed") ? <CheckCircleOutlineIcon sx={{ color: green[500] }} /> : <DoNotDisturbIcon sx={{ color: red[500] }} />}
							</ListItemIcon>
							<ListItemText
								primary={`Prescribe S4: ${(vet.canPrescribeS4 && vet.registrationType !== "removed") ? "True" : "False"}`}
								secondary={`Schedule IV Controlled Substances`}
							/>
						</ListItem>
						<ListItem key="2">
							<ListItemIcon>
								{(vet.canPrescribeS4D && vet.registrationType !== "removed") ? <CheckCircleOutlineIcon sx={{ color: green[500] }} /> : <DoNotDisturbIcon sx={{ color: red[500] }} />}
							</ListItemIcon>
							<ListItemText
								primary={`Prescribe S4D: ${(vet.canPrescribeS4D && vet.registrationType !== "removed") ? "True" : "False"}`}
								secondary={`Schedule IV Appendix D Controlled Substances`}
							/>
						</ListItem>
						<ListItem key="3">
							<ListItemIcon>
								{(vet.canPrescribeS8 && vet.registrationType !== "removed") ? <CheckCircleOutlineIcon sx={{ color: green[500] }} /> : <DoNotDisturbIcon sx={{ color: red[500] }} />}
							</ListItemIcon>
							<ListItemText
								primary={`Prescribe S8: ${(vet.canPrescribeS8 && vet.registrationType !== "removed") ? "True" : "False"}`}
								secondary={`Schedule VIII Medicines of Addiction`}
							/>
						</ListItem>
						<ListItem key="4">
							<ListItemIcon>
								{(vet.canDispenseS4 && vet.registrationType !== "removed") ? <CheckCircleOutlineIcon sx={{ color: green[500] }} /> : <DoNotDisturbIcon sx={{ color: red[500] }} />}
							</ListItemIcon>
							<ListItemText
								primary={`Dispense S4: ${(vet.canDispenseS4 && vet.registrationType !== "removed") ? "True" : "False"}`}
								secondary={`Schedule IV Controlled Substances`}
							/>
						</ListItem>
						<ListItem key="5">
							<ListItemIcon>
								{(vet.canDispenseS4D && vet.registrationType !== "removed") ? <CheckCircleOutlineIcon sx={{ color: green[500] }} /> : <DoNotDisturbIcon sx={{ color: red[500] }} />}
							</ListItemIcon>
							<ListItemText
								primary={`Dispense S4D: ${(vet.canDispenseS4D && vet.registrationType !== "removed") ? "True" : "False"}`}
								secondary={`Schedule IV Appendix D Controlled Substances`}
							/>
						</ListItem>
						<ListItem key="6">
							<ListItemIcon>
								{(vet.canDispenseS8 && vet.registrationType !== "removed") ? <CheckCircleOutlineIcon sx={{ color: green[500] }} /> : <DoNotDisturbIcon sx={{ color: red[500] }} />}
							</ListItemIcon>
							<ListItemText
								primary={`Dispense S8: ${(vet.canDispenseS8 && vet.registrationType !== "removed") ? "True" : "False"}`}
								secondary={`Schedule VIII Medicines of Addiction`}
							/>
						</ListItem>
					</List>
				</>

			</DialogContent>
		</Dialog>
	);
};

export default VetInfoDialog;
