import { TextField, Button, Autocomplete, Box } from "@mui/material";
import { useEffect, useState } from "react";
import axios from "axios";

type SearchParams = {
    GivenName: string;
    Surname: string;
    Email: string;
    Phone: string;
    RegistrationNumber: string;
    Specialities: string[];
    Region_State: RegionOption;
};

type SearchFormProps = {
    onSearch: (params: any) => void;
};

export interface RegionOption {
    readonly "regionId": string,
    readonly "country": string,
    readonly "region_State": string,
    readonly "parent_Region": string,
    readonly "vetBoard_Name": string,
    readonly "vetBoard_Url": string,
    readonly "contact_Email": string,
    readonly "contact_Phone": string
}




export default function SearchForm({ onSearch }: SearchFormProps) {

    const defaultRegion: RegionOption = { region_State: 'Any', country: 'Australia', regionId: '0', parent_Region: '-1', vetBoard_Name: 'Any', vetBoard_Url: '', contact_Email: '', contact_Phone: '' };
    const [options, setOptions] = useState<RegionOption[]>([]);
    useEffect(() => {
        promiseOptions();
    }, []);

    const [searchParams, setSearchParams] = useState<SearchParams>({
        GivenName: "",
        Surname: "",
        Email: "",
        Phone: "",
        RegistrationNumber: "",
        Specialities: [],
        Region_State: defaultRegion,
    });

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        //console.log(`name=${name}, value=${value}`);
        setSearchParams((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleRegionChange = (option: RegionOption | null) => {
        setSearchParams((prevState) => ({
            ...prevState,
            Region_State: option!,
        }));

    }

    const handleSearch = () => {
        const given = searchParams.GivenName.length > 0 ? `Given_Name=${searchParams.GivenName}&` : '';
        const surname = searchParams.Surname.length > 0 ? `Surname=${searchParams.Surname}&` : '';
        const email = searchParams.Email.length > 0 ? `Email=${searchParams.Email}&` : '';
        const phone = searchParams.Phone.length > 0 ? `Phone=${searchParams.Phone}&` : '';
        const registrationNumber = searchParams.RegistrationNumber.length > 0 ? `RegistrationNumber=${searchParams.RegistrationNumber}&` : '';
        const state = searchParams.Region_State.region_State !== 'any' ? `Region_State=${searchParams.Region_State.region_State}&` : '';
        const searchUrl = `https://api.vetdirectory.info/FindVet?${given}${surname}${email}${phone}${registrationNumber}${state}`;
     //  const searchUrl = `http://localhost/FindVet?${given}${surname}${email}${phone}${vetId}${state}`;
        //console.log('Here\'s the params');
        //console.log(searchParams);
        //console.log('Here\'s the search');
        //console.log(searchUrl);

        axios.get(searchUrl)
            .then((response) => {

                //console.log('Here\'s the response');
                //console.log(response);

                const vets = response.data.map((vetValues: any) => ({
                    vetId: vetValues.vetId,
                    givenName: vetValues.givenName,
                    surname: vetValues.surname,
                    email: vetValues.email,
                    phone: vetValues.phone,
                    registrationNumber: vetValues.registrationNumber,
                    registrationType: vetValues.registrationType,
                    specialities: vetValues.specialities.$values.map((s:any) => s.title),
                    region_State: vetValues.region_State,
                    rights: {
                        value1: vetValues.canDispenseS4,
                        value2: vetValues.canDispenseS4D,
                        value3: vetValues.canDispenseS8,
                        value4: vetValues.canPrescribeS4,
                        value5: vetValues.canPrescribeS4D,
                        value6: vetValues.canPrescribeS8,
                        value7: vetValues.canSupervise
                    },

                }));

                //console.log('Here\'s the vets');
                //        console.log(vets);
                onSearch(vets);
                // handle response data here
            })
            .catch((error) => {
                // handle errors here
                console.log(error);
            });
    };

    const promiseOptions = () =>
        new Promise<RegionOption[]>((resolve) => {
            setTimeout(() => {
              // axios.get('http://localhost/GetRegions', {
               axios.get('https://api.vetdirectory.info/GetRegions', {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                    }
                })
                    .then(response => {
                        resolve(response.data.map(function (region: RegionOption) { return { value: region.regionId, label: region.region_State, key: region.regionId, country: region.country } }));
                        const newOptions = response.data;
                        const updatedOptions = [defaultRegion, ...newOptions]
                        setOptions(updatedOptions);
                        //const storedVoicePreference = localStorage.getItem('selectedVoice')!;
                        //if (storedVoicePreference) {
                        //    setSelectedVoice(updatedOptions.find((n: RegionOption) => n.key === storedVoicePreference)!);
                        //    dispatch(setNewVoice(updatedOptions.find((n: RegionOption) => n.key === storedVoicePreference)));

                        //}
                        //console.log(`Stored voice is ${storedVoicePreference}, selected voice is ${selectedVoice.key}`);


                    })
                    .catch(error => {
                        console.log(error);
                    });
            }, 1000);
        });


    return (
        <>
            <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={options}
                sx={{ marginTop: '16px' }}
                onChange={(event, values) => { handleRegionChange(values) }}
                getOptionLabel={(option: RegionOption) => `${option.country} - ${option.region_State}`}
                value={searchParams.Region_State}
                renderInput={(params) => <TextField {...params} label="Region" />}
                renderOption={(props, option) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        {option.country} - {option.region_State}
                    </Box>)}
            />
            <TextField
                label="Registration Number"
                name="RegistrationNumber"
                placeholder="Enter the Veterinarian Registration Number"
                value={searchParams.RegistrationNumber}
                onChange={handleInputChange}
                onKeyDown={event => {
                    const regex = new RegExp(/(^\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/);
                    return !event.key.match(regex) && event.preventDefault();
                }}
                fullWidth
                margin="normal"
                type="number"
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                helperText="Only enter numbers"

            />
          <TextField
                label="Given Name"
                name="GivenName"
                placeholder="Enter the given name"
                value={searchParams.GivenName}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
            />
            <TextField
                label="Surname"
                name="Surname"
                placeholder="Enter the surname"
                value={searchParams.Surname}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
            />
            <Button variant="contained" sx={{ margin: '16px' }} onClick={handleSearch}>Find</Button>
        </>
    );
}
