// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
}

.checkbox-header {
    writing-mode: vertical-rl; /* Vertical text */
    transform: rotate(180deg); /* Rotate 180 degrees for vertical alignment */
    text-align: left; /* Center align text vertically */
    white-space: nowrap; /* Prevent text from wrapping */
    line-height: 1.43;
}


.App-logo {
  height: 10vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo-spin {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
    background-color: #2c4063;
    min-height: 10vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    position: relative;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;IACI,yBAAyB,EAAE,kBAAkB;IAC7C,yBAAyB,EAAE,8CAA8C;IACzE,gBAAgB,EAAE,iCAAiC;IACnD,mBAAmB,EAAE,+BAA+B;IACpD,iBAAiB;AACrB;;;AAGA;EACE,cAAc;EACd,oBAAoB;AACtB;;AAEA;EACE;IACE,4CAA4C;EAC9C;AACF;;AAEA;IACI,yBAAyB;IACzB,gBAAgB;IAChB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,6BAA6B;IAC7B,YAAY;IACZ,kBAAkB;AACtB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF","sourcesContent":[".App {\n  text-align: center;\n}\n\n.checkbox-header {\n    writing-mode: vertical-rl; /* Vertical text */\n    transform: rotate(180deg); /* Rotate 180 degrees for vertical alignment */\n    text-align: left; /* Center align text vertically */\n    white-space: nowrap; /* Prevent text from wrapping */\n    line-height: 1.43;\n}\n\n\n.App-logo {\n  height: 10vmin;\n  pointer-events: none;\n}\n\n@media (prefers-reduced-motion: no-preference) {\n  .App-logo-spin {\n    animation: App-logo-spin infinite 20s linear;\n  }\n}\n\n.App-header {\n    background-color: #2c4063;\n    min-height: 10vh;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    font-size: calc(10px + 2vmin);\n    color: white;\n    position: relative;\n}\n\n.App-link {\n  color: #61dafb;\n}\n\n@keyframes App-logo-spin {\n  from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
