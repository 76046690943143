import React, { useEffect, useState } from 'react';

import axios from 'axios';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
    Link,
    List,
    ListItem,
    ListItemText,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Divider,
    ListItemIcon,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import { red, green } from '@mui/material/colors';
import CircleIcon from '@mui/icons-material/Circle';

interface TOSDialogProps {
    open: boolean;
    onClose: () => void;

}

const TOSDialog: React.FC<TOSDialogProps> = ({ open, onClose }) => {

    useEffect(() => {
        if (open) {
        }
    }, [open]);

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
            <DialogTitle>
                <Typography variant='h4'> Vet Directory Terms of Service </Typography>
            </DialogTitle>
            <DialogContent dividers>
                <>
                    <Typography variant="h5" paragraph>
                        Data Sync Status
                    </Typography>

                    <Typography variant='body1'>
                        The current data synchronization status with each of the Australian states and territories is as follows:
                    </Typography>

                    <List dense>
                        <ListItem key="NSW">
                            <ListItemIcon>
                                <CircleIcon sx={{mx: 0.5,fontSize: 12}} />
                            </ListItemIcon>
                          <ListItemText primary="New South Wales (NSW):" secondary="Enabled" />
                        </ListItem>
                        <ListItem key="VIC">
                            <ListItemIcon>
                                <CircleIcon sx={{ mx: 0.5, fontSize: 12 }} />
                            </ListItemIcon>
                           <ListItemText primary="Victoria (VIC):" secondary="Enabled" />
                        </ListItem>
                        <ListItem key="QLD">
                            <ListItemIcon>
                                <CircleIcon sx={{ mx: 0.5, fontSize: 12 }} />
                            </ListItemIcon>
                           <ListItemText primary="Queensland (QLD):" secondary="Enabled" />
                        </ListItem>
                        <ListItem key="SA">
                            <ListItemIcon>
                                <CircleIcon sx={{ mx: 0.5, fontSize: 12 }} />
                            </ListItemIcon>
                           <ListItemText primary="South Australia (SA):" secondary="Enabled" />
                        </ListItem>
                        <ListItem key="WA">
                            <ListItemIcon>
                                <CircleIcon sx={{ mx: 0.5, fontSize: 12 }} />
                            </ListItemIcon>
                          <ListItemText primary="Western Australia (WA):" secondary="Limited" />
                        </ListItem>
                        <ListItem key="ACT">
                            <ListItemIcon>
                                <CircleIcon sx={{ mx: 0.5, fontSize: 12 }} />
                            </ListItemIcon>
                           <ListItemText primary="Australian Capital Territory (ACT):" secondary="Enabled" />
                        </ListItem>
                        <ListItem key="NT">
                            <ListItemIcon>
                                <CircleIcon sx={{ mx: 0.5, fontSize: 12 }} />
                            </ListItemIcon>
                          <ListItemText primary="Northern Territory (NT):" secondary="Enabled" />
                        </ListItem>
                    </List>

                    <Typography variant='body1' paragraph>
                        This status reflects the real-time data syncing capability between VetDirectory and the respective state or territory's veterinary board. Users should note that while syncing is enabled, data accuracy is dependent on the provided data from each state or territory board, as outlined in the "Data Accuracy" section.
                    </Typography>

                    <Typography variant="h5" paragraph>
                        Terms and Conditions for VetDirectory
                    </Typography>

                    <Typography variant='body1' paragraph>
                        Welcome to VetDirectory! These terms and conditions outline the rules and regulations for the use of VetRx Pty Ltd's web application, located at https://vetdirectory.info .
                    </Typography>

                    <Typography variant='body1' paragraph>
                        By accessing and using this web application, you accept and agree to be bound by these terms and conditions. If you disagree with any part of these terms and conditions, please do not use our web application.
                    </Typography>

                    <Typography variant="h6" paragraph>
                        Purpose
                    </Typography>

                    <Typography variant='body1' paragraph>
                        VetDirectory collates data from State veterinary boards across Australia to offer a national directory service. This service aids businesses in real-time verification and validation of a veterinarian's registration status.
                    </Typography>

                    <Typography variant='h6' paragraph>
                        Data Accuracy
                    </Typography>

                    <Typography variant='body1' paragraph>
                        VetDirectory is not the registering authority of veterinarians. While we aim to provide accurate and up-to-date information, VetDirectory relies on State veterinary boards for its data. Therefore, any discrepancies or errors in the data at the State level will be reflected in the VetDirectory database.
                    </Typography>

                    <Typography variant='h6' paragraph>
                       Data Update Commitment
                    </Typography>

                    <Typography variant='body1' paragraph>
                        VetDirectory assures users that the data is updated within 24 hours of any change notified by the respective veterinary board.
                    </Typography>

                    <Typography variant='h6' paragraph>
                        License
                    </Typography>

                    <Typography variant='body1' paragraph>
                        Unless otherwise stated, VetRx Pty Ltd owns the intellectual property rights for all material on VetDirectory. All intellectual property rights are reserved.
                    </Typography>

                    <Typography variant='h6' paragraph>
                        Restrictions
                    </Typography>

                    <Typography variant='body1' paragraph>

                        Users are expressly restricted from:

                        <List dense>
                            <ListItem key="1">
                                <ListItemIcon>
                                    <CircleIcon sx={{ mx: 0.5, fontSize: 12 }} />
                                </ListItemIcon>
                                <ListItemText primary="Engaging in any malicious activity, such as distributing malware, viruses, or harmful code." />
                            </ListItem>
                            <ListItem key="2">
                                <ListItemIcon>
                                    <CircleIcon sx={{ mx: 0.5, fontSize: 12 }} />
                                </ListItemIcon>
                               <ListItemText primary="Engaging in any data mining, data harvesting, or any other similar activity.." />
                            </ListItem>
                            <ListItem key="3">
                                <ListItemIcon>
                                    <CircleIcon sx={{ mx: 0.5, fontSize: 12 }} />
                                </ListItemIcon>
                              <ListItemText primary="Using this web application in a way that is damaging or impacts user access." />
                            </ListItem>
                        </List>
                    </Typography>

                    <Typography variant='h6' paragraph>
                        Limitation of Liability
                    </Typography>

                    <Typography variant='body1' paragraph>
                        To the fullest extent permitted by applicable law, VetRx Pty Ltd shall not be liable for any indirect, consequential, or special liability arising out of or in any way related to your use of this web application.
                    </Typography>

                    <Typography variant='h6' paragraph>
                        Termination
                    </Typography>

                    <Typography variant='body1' paragraph>
                        We reserve the right to terminate or suspend access to our web application without prior notice or liability, for any reason whatsoever.
                    </Typography>

                    <Typography variant='h6' paragraph>
                        Changes
                    </Typography>

                    <Typography variant='body1' paragraph>
                        VetRx Pty Ltd reserves the right to modify these terms and conditions at any time. We will provide notice of these changes on our website, but it is your responsibility to review this page periodically.
                    </Typography>

                    <Typography variant='h6' paragraph>
                        Governing Law
                    </Typography>

                    <Typography variant='body1' paragraph>
                        These terms and conditions will be governed by and interpreted in accordance with the laws of Australia, and you submit to the non-exclusive jurisdiction of the state and federal courts located in Australia for the resolution of any disputes.
                    </Typography>

                    <Typography variant='h6' paragraph>
                        Conditions for Use
                    </Typography>
                    <ListItem key="1">
                        <ListItemIcon>
                            <CircleIcon sx={{ mx: 0.5, fontSize: 12 }} />
                        </ListItemIcon>
                        <ListItemText primary="Private Use: VetDirectory is available for free for private, non-commercial use." />
                    </ListItem>
                    <ListItem key="2">
                        <ListItemIcon>
                            <CircleIcon sx={{ mx: 0.5, fontSize: 12 }} />
                        </ListItemIcon>
                        <ListItemText primary="Commercial Use & API Access: If you wish to use VetDirectory for commercial purposes or require access to our API, you must obtain permission." />
                    </ListItem>
                    <Typography>Please contact us at
                    <Link href="mailto:vetdirectory@vetrx.com.au"> vetdirectory@vetrx.com.au</Link> for details on pricing and licensing arrangements.</Typography>
                </>
            </DialogContent>
        </Dialog >
    );
};

export default TOSDialog;
